import React from 'react'
import styled from '@emotion/styled'
import svgOverlay from './CF_Overlay_SVG.svg'
import Logo from '../Logo'
import HeroDesktopImg from './Hero_Logos.jpg'
import HeroMobileImg from './Hero_Logos_Mobile.jpg'
import Button from '../Button'

function GoosebumpsDON () {
  return (
    <AngledWindow>
      <Logo />
      <AButton href="https://www.goosebumpsgame.com" target="_blank">
        Get it Now
      </AButton>
    </AngledWindow>
  )
}

const AngledWindow = styled.div`
  margin: 0 1rem;
  width: auto;
  max-width: 1320px;
  height: 58vw;
  max-height: 800px;
  background-image: url(${HeroMobileImg});
  mask-image: url(${svgOverlay});
  background-repeat: no-repeat;
  background-size: cover;
  mask-repeat: no-repeat;
  mask-size: 100%;
  background-position: 40% bottom;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @media (min-width: 480px) {
    height: 55vw;
    margin: 0 3rem;
  }

  @media (min-width: 768px) {
    height: 58vw;
    background-image: url(${HeroDesktopImg});
  }
  
  @media (min-width: 1200px) {
    height: 60vw;
  }
  

  @media (min-width: 1320px) {
    margin: 0 auto;
  }
`

const AButton = Button.withComponent('a')


export default GoosebumpsDON
