import React from 'react'
import styled from '@emotion/styled'

const Button = styled.button`
  text-decoration: none;
  font-size: 1.2rem;
  padding: 6px 11px;
  color: rgba(255,255,255, .75);
  font-weight: 700;
  font-family: 'Nunito Sans', 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255,255,255, .75);
  cursor: pointer;
  transition: all 300ms ease-in;
  display: inline-block;

  &:hover {
    color: #dc394c;
    border: 2px solid #dc394c;
    transform: translate3d(0.5px, -1px, 0);
    /* background-color: rgba(27, 68, 67, 0); */
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
    padding: 12px 22px;
    border: 2px solid rgba(255,255,255, .75);
  }
`


export default Button
