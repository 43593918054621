import React from 'react'
import styled from '@emotion/styled'
import MadeByCF from './MadeByCF.png'

function MoreTitlesSection() {
  return (
    <div style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '6rem' }}>
      <img src={MadeByCF} style={{ margin: '3rem auto' }} width="120" />
    </div>
  )
}

export default MoreTitlesSection
