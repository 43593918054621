import React from 'react'
import styled from '@emotion/styled'
import LogoImg from './Logo.png'

function Logo(props) {
  return <Img src={LogoImg} {...props } />
}

const Img = styled.img`
  
  max-width: 50%;
  height: auto;
  margin-top: 10%;
`

export default Logo

