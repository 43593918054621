import React, { useState } from 'react'
import styled from '@emotion/styled'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Form, { addNetlify } from './Form'
import { H1 } from './global-styles'

function SupportContact({ title, formName }) {
  const [open, setOpen] = useState(true)
  return (
    <Container>
      <Header>
        <H1 onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          Press Inquiries <Caret icon={open ? faCaretDown : faCaretRight} />
        </H1>
      </Header>
      {open && (
        <>
          <FormContainer>
            <NetlifyForm name="Media-Inquiries">
              <Form.Group>
                <Input full name="name" placeholder="Name" />
                <Input full name="email" placeholder="Email" />
              </Form.Group>
              <TextArea name="message" placeholder="Message" full rows={6} />
              <FormButton type="submit"
                style={{
                  background: 'transparent',
                  textShadow: 'none',
                  boxShadow: 'none'
                }}
              >Send Message</FormButton>
            </NetlifyForm>
          </FormContainer>
        </>
      )}
      <Header style={{ display: 'initial' }}>
        <H1 style={{ margin: '8rem 0 4rem 0' }}>Download Press Kit</H1>
        <DownloadButton
          href="Goosebumps_DON_PressKit_v1.0.zip"
          style={{
            background: 'transparent',
            textShadow: 'none',
            boxShadow: 'none'
          }}
        >
          Download
        </DownloadButton>
      </Header>
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  margin: 3rem 0 6rem 0;

  color: #afafaf;
  a {
    color: #41c8c7;
    text-decoration: none;
  }
`

const DownloadButton = Button.withComponent('a')

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Caret = styled(FontAwesomeIcon)`
  vertical-align: middle;
  margin-left: 2rem;
  font-size: 0.75em;
`

const FormContainer = styled.div`
  list-style-type: none;
  font-size: 4.6rem;
  text-transform: uppercase;
  margin: 3rem auto;
  max-width: 800px;
`

const Input = styled(Form.Input)`
  border: 2px solid black;
  font-size: 2rem;
  font-family: 'Nunito Sans', 'Arial Narrow Bold', sans-serif;
`

const TextArea = styled(Form.TextArea)`
  border: 2px solid black;
  font-size: 2rem;
  font-family: 'Nunito Sans', 'Arial Narrow Bold', sans-serif;
`

const FormButton = styled(Button)`
  font-size: 2rem;
  cursor: pointer;
`

const NetlifyForm = addNetlify(Form)

export default SupportContact
