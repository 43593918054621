import React, { useState, useRef, useEffect } from 'react'
import { animated, useTransition, useSpring, useChain, config } from 'react-spring'
import styled from '@emotion/styled'
import Button from '../Button'
import amazon from './amazon.png'
import appstore from './appstore.png'
import occulus from './occulus.png'
import playstore from './playstore.png'
import nosBG from './nosBG.jpg'
import NoSLogo from './Goosebumps_NoSLogo.png'
import Media from 'react-media'

const data = [
  {
    key: '1',
    src: appstore,
    url: 'https://itunes.apple.com/us/app/goosebumps-night-of-scares/id1034091427?mt=8'
  },
  {
    key: '2',
    src: playstore,
    url: 'https://play.google.com/store/apps/details?id=com.freerange.goosebumps'
  },
  {
    key: '3',
    src: amazon,
    url: 'https://www.amazon.com/Cosmic-Forces-Goosebumps-Night-Scares/dp/B016CPDR1A'
  },
  {
    key: '4',
    src: occulus,
    url: 'https://www.oculus.com/experiences/gear-vr/888655704564160/'
  }
]

function NightOfScares() {
  const [open, setToggle] = useState(false)
  const [loading, setLoading] = useState(true)

  const springRef = useRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    // config: { duration: 100 },
    from: { opacity: 1, transform: 'scale(1)' },
    to: { opacity: open ? 0 : 1, transform: open ? 'scale(0)' : 'scale(1)' }
  })

  const transRef = useRef()
  const transitions = useTransition(open ? data : [], item => item.key, {
    ref: transRef,
    unique: true,
    trail: 400 / data.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  })

  useChain(open ? [transRef, springRef] : [springRef, transRef], [0, open ? 0.1 : 0.6])

  useEffect(() => {
    setLoading(false)
  }, [])

  function toggle(e) {
    e.preventDefault()
    setToggle(!open)
  }

  function openDownloads(e) {
    e.preventDefault()
  }

  // Prevents rendering on build since media queries are browser dependent
  if (loading) return null

  return (
    <Media queries={{
      desktop: "(min-width: 900px)"
    }}>
      {matches =>
        matches.desktop ? (
          <>
            <Root style={{ textAlign: 'center' }}>
              <NOSLogoImg src={NoSLogo} alt="Goosebumps: Night of Scares" width="474" />
              <Container>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'center',
                    margingTop: -25,
                    height: 60
                  }}
                >
                  <AButton style={{ ...rest, transition: 'none' }} onClick={toggle}>
                    Get it Now
                  </AButton>
                </div>
                {transitions.map(({ item, key, props }) => (
                  <A href={item.url} target="_blank" key={key}>
                    <Img src={item.src} style={{ ...props, height: 60, width: 179 }} />
                  </A>
                ))}
              </Container>
              <p style={{ marginTop: 20, textAlign: 'center' }}>
                <CtaLink href="https://www.goosebumpsgame.com">Visit Site</CtaLink>
              </p>
            </Root>
          </>
        ) : (
            <>
              <MobileRoot style={{ textAlign: 'center' }}>
                <MobileNOSLogoImg src={NoSLogo} alt="Goosebumps: Night of Scares" width="474" />
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <AButton style={{ ...rest, transition: 'none', textShadow: 'none', boxShadow: 'none', background: 'none' }} onClick={toggle}>
                    Get it Now
                </AButton>
                {!open && 
                  <p>
                    <CtaLink href="https://www.goosebumpsgame.com">Visit Site</CtaLink>
                  </p>
                }
                <ButtonGrid style={{ marginTop: -30 }}>
                {transitions.map(({ item, key, props }) => (
                  <A href={item.url} target="_blank" key={key}>
                    <Img src={item.src} style={{ ...props, height: 'auto', width: 100, maxWidth: '100%' }} />
                  </A>
                ))}
                </ButtonGrid>
                </div>
              </MobileRoot>
              <MobileContainer>
              </MobileContainer>
 
            </>
          )
      }
    </Media>
  )
}

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  max-width: 230px;
  margin: 0 auto;
`

const CtaLink = styled.a`
  color: #f4c350;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;

  &:hover {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: black;
  }

  @media (min-width: 900px) {
  color: #f4c350;
  font-size: 1.8rem;

    &:hover {
      border-bottom-color: #f4c350;
    }
  }
`

const MobileRoot = styled.div`
  border-radius: 10px;
  background-image: url(${nosBG});
  background-repeat: no-repeat;
  margin: 0 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-size: cover;
  background-position: center right;
  height: 190px;

  @media (min-width: 480px) {
    margin: 0 3rem;
    height: 220px;
  }

  @media (min-width: 768px) {
    height: 280px;
  }
`
const MobileContainer = styled(animated.div)`
  position: relative;
  display: grid;
  grid-template-columns: 179px;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 5rem;
  justify-content: center;
  grid-gap: 5px;
  @media (min-width: 385px) {
    grid-template-columns: 179px 179px;

  }
  @media (min-width: 768px) {
    grid-template-columns: auto repeat(4, minmax(100px, 180px)) auto;
    grid-gap: 25px;
  }
`


const Root = styled.div`
  border-radius: 24px;
  background-image: url(${nosBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  margin: 0 3rem;
  max-width: 1320px;
  height: 460px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  @media (min-width: 1320px) {
    margin: 0 auto;
  }
`

const AButton = animated(Button)

const Img = styled(animated.img)`
  border: 2px solid white;
  border-radius: 8px;
  background-color: black;
`

const A = styled.a`
  &:nth-child(2) {
    grid-column-start: auto;
  }
  @media (min-width: 768px) {
    &:nth-child(2) {
      grid-column-start: 2;
    }
  }
`

const Container = styled(animated.div)`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  height: 60px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: auto repeat(4, minmax(100px, 180px)) auto;
  }
`

const NOSLogoImg = styled.img`
  display: block;
  margin-bottom: 2rem;
  max-width: 48%;
  height: auto;
`

const MobileNOSLogoImg = styled.img`
  padding: 2rem 4rem 1rem;
  max-width: 48%;
  height: auto;
  align-self: center;
`

export default NightOfScares
