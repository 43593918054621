import React, { useState, useEffect } from 'react'
import Header from './header'
import HeroSection from './Hero'
import CopySection from './Copy'
import VideoBackground from './VideoBackground'
import video from './VideoBackground/CosmicForces_HeaderVideo_v04.mp4'
import styled from '@emotion/styled'
import GoosebumpsDON from './Goosebumps'
import NightOfScares from './NightOfScares'
import MoreTitlesSection from './MoreTitles'
import FooterSection from './Footer'
import Media from 'react-media'
import HeroBackgroundMobile from './hero-background-mobile.jpg'
import '../styles/layout.css'
import CFLogo from './CF-Logo.png'
import CookieBanner from './CookieBanner'
import MediaContact from './MediaContact'

function Layout({ onHideNav, onShowNav, showNav, siteTitle, navMenu, footerMenu, socialMenu }) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return null

  return (
    <>
      <CookieBanner to="/privacy-policy" />
      <StyledHero>
        <Media
          queries={{
            desktop: '(min-width: 768px)'
          }}
        >
          {matches => (
            <>
              {matches.desktop && <VideoBackground src={video} />}
              <Header
                siteTitle={siteTitle}
                onHideNav={onHideNav}
                onShowNav={onShowNav}
                showNav={showNav}
                navMenu={navMenu}
              />
              {
                <LogoContainer>
                  {!matches.desktop && <img src={CFLogo} alt="Cosmic Forces" />}
                </LogoContainer>
              }
            </>
          )}
        </Media>
      </StyledHero>
      <CopySection />
      <GoosebumpsDON />
      <NightOfScares />
      <MoreTitlesSection />
      <MediaContact />
      <FooterSection footerMenu={footerMenu} socialMenu={socialMenu} />
    </>
  )
}

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 90%;
    height: auto;
    display: block;
  }
`

const StyledHero = styled(HeroSection)`
  clip-path: none;
  background: url(${HeroBackgroundMobile}) center center no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    clip-path: polygon(0 0, 100% 0%, 100% 80vh, 0 100%);
  }
`

export default Layout
